<template>
  <v-card :loading="loading" flat>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <td>
              <v-btn @click="loadData" color="primary" small fab>
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </td>
          </tr>
          <tr>
            <th class="text-center">File Name</th>
            <th class="text-center">Submitted By</th>
            <th class="text-center">Created At</th>
            <th class="text-center">Updated At</th>
            <th class="text-center">Submitted At</th>
            <th class="text-center">Access</th>
            <th class="text-center">Download</th>
            <th class="text-center">Archive</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in submittedFiles" :key="item.id">
            <td class="text-center">{{ item.file_name }}</td>
            <td class="text-center">{{ item.submitted }}</td>
            <td class="text-center">{{ formattedDate(item.created_at) }}</td>
            <td class="text-center">{{ formattedDate(item.updated_at) }}</td>
            <td class="text-center">{{ formattedDate(item.deleted_at) }}</td>
            <td class="text-center">
              <div v-if="item.file_name.includes('labtest')">
                <AddReSubmitResults :file-name="item.file_name"></AddReSubmitResults>
              </div>
              <div v-else>
                <AddReSubmitProducts :file-name="item.file_name"></AddReSubmitProducts>
              </div>
            </td>
            <td class="text-center">
              <v-btn @click="downloadFile(item.file_name)"
                     elevation="2" color="primary" :loading="loadingDownload">
                Download
              </v-btn>
            </td>
            <td class="text-center">
              <v-btn @click="archiveFile(item.file_name)"
                     elevation="2" color="secondary" :loading="loadingArchive">
                Archive
              </v-btn>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import ccrsApi from "@/lib/ccrs-api";
import dateFormatter from "@/lib/dateFormatter";
export default {
  name: "SubmittedFiles.vue",
  components: {
    AddReSubmitResults: () =>  import('./AddReSubmitResults.vue'),
    AddReSubmitProducts: () =>  import('./AddReSubmitProducts.vue'),
  },
  mounted() {
    this.loadData();
  },
  data: () => ({
    loading: false,
    loadingDownload: false,
    loadingArchive: false,
    accessFile: null,
    fileData: [],
    submittedFiles: [],
  }),
  methods: {
    async loadData() {
      this.loading = true;
      let query = await ccrsApi.get('/v2/labNewFiles/submitted');
      this.submittedFiles = query.data;
      this.loading = false;
    },
    async downloadFile(fileName) {
      this.loadingDownload = true;
      let fileType = '';
      if (fileName.includes('labtest')) {
        fileType = 'labResults';
      } else {
        fileType = 'labProducts';
      }

      let query = await ccrsApi.download('/v2/' + fileType + '/download/' + fileName);
      let content = new Blob([query.data], {type: `application/csv`});
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(content);
      link.download = `${fileName}`;
      link.click();
      this.loadingDownload = false;
    },
    async archiveFile(fileName) {
      this.loadingArchive = true;
      await ccrsApi.get('/v2/labNewFiles/archive/' + fileName);
      this.loadData();
      this.loadingArchive = false;
    },
    setAccessFile(fileName) {
      this.accessFile = fileName;
    },
    formattedDate(dateString) {
      return dateFormatter.formatDate(dateString);
    },
  },
}
</script>

<style scoped>

</style>